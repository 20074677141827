import Acacias from '../assets/imags/proyects/acacias/acacias.jpg'
import Acacias2 from '../assets/imags/proyects/acacias/acacias.2.jpg'
import Acacias3 from '../assets/imags/proyects/acacias/acacias.3.jpg'
import Acacias4 from '../assets/imags/proyects/acacias/acacias.4.jpg'
import Acaciaspor from '../assets/imags/proyects/acacias/portacacias.jpg'



import AlissosP from '../assets/imags/proyects/alissos/portadaALISOS.jpg'
import Alissos from '../assets/imags/proyects/alissos/alisos.jpg'
import Alisos2 from '../assets/imags/proyects/alissos/alisos2.jpg'
import Alisos3 from '../assets/imags/proyects/alissos/alisos3 (2).jpg'
import Alisos4 from '../assets/imags/proyects/alissos/alisos4 (2).jpg'
import Alisos5 from '../assets/imags/proyects/alissos/alisos5.jpg'



import Araucarias from '../assets/imags/proyects/araucariasI/ar1.1.jpg'
import AraucariasP from '../assets/imags/proyects/araucariasI/portadaAR1.jpg'
import AraucariasI2 from '../assets/imags/proyects/araucariasI/ar1.2.jpg'
import AraucariasI3 from '../assets/imags/proyects/araucariasI/ar1.3.jpg'
import AraucariasI4 from '../assets/imags/proyects/araucariasI/ar1.4.jpg'
import AraucariasI5 from '../assets/imags/proyects/araucariasI/ar1.5.jpg'
import AraucariasI6 from '../assets/imags/proyects/araucariasI/ar1.6.jpg'
import AraucariasI7 from '../assets/imags/proyects/araucariasI/ar1.7.jpg'
import AraucariasI8 from '../assets/imags/proyects/araucariasI/ar1.8.jpg'
import AraucariasI9 from '../assets/imags/proyects/araucariasI/ar1.9.jpg'
import AraucariasI10 from '../assets/imags/proyects/araucariasI/ar1.10.jpg'
import AraucariasI11 from '../assets/imags/proyects/araucariasI/ar1.11.jpg'





import Araucarias2P from '../assets/imags/proyects/araucariasII/portadaar2.jpg'
import AraucariasII1 from '../assets/imags/proyects/araucariasII/ar2.1.jpg'
import AraucariasII2 from '../assets/imags/proyects/araucariasII/ar2.2.jpg'
import AraucariasII3 from '../assets/imags/proyects/araucariasII/ar2.3.jpg'
import AraucariasII4 from '../assets/imags/proyects/araucariasII/ar2.4.jpg'
import AraucariasII5 from '../assets/imags/proyects/araucariasII/ar2.5.jpg'
import AraucariasII6 from '../assets/imags/proyects/araucariasII/ar2.6.jpg'
import AraucariasII7 from '../assets/imags/proyects/araucariasII/ar2.7.jpg'
import AraucariasII8 from '../assets/imags/proyects/araucariasII/ar2.8.jpg'
import AraucariasII9 from '../assets/imags/proyects/araucariasII/ar2.9.jpg'
import AraucariasII10 from '../assets/imags/proyects/araucariasII/ar2.10.jpg'
import AraucariasII11 from '../assets/imags/proyects/araucariasII/ar2.11.jpg'
import AraucariasII12 from '../assets/imags/proyects/araucariasII/ar2.12.jpg'

import Araucarias3P from '../assets/imags/proyects/araucariasIII/portadaAR3.jpg'
import Araucarias3 from '../assets/imags/proyects/araucariasIII/ar3.1.jpg'
import AraucariasIII2 from '../assets/imags/proyects/araucariasIII/ar3.2.jpg'
import AraucariasIII3 from '../assets/imags/proyects/araucariasIII/ar3.3.jpg'
import AraucariasIII4 from '../assets/imags/proyects/araucariasIII/ar3.4.jpg'
import AraucariasIII5 from '../assets/imags/proyects/araucariasIII/ar3.5.jpg'
import AraucariasIII6 from '../assets/imags/proyects/araucariasIII/ar3.6.jpg'
import AraucariasIII7 from '../assets/imags/proyects/araucariasIII/ar3.7.jpg'
import AraucariasIII8 from '../assets/imags/proyects/araucariasIII/ar3.8.jpg'
import AraucariasIII9 from '../assets/imags/proyects/araucariasIII/ar3.9.jpg'
import AraucariasIII10 from '../assets/imags/proyects/araucariasIII/ar3.10.jpg'
import AraucariasIII11 from '../assets/imags/proyects/araucariasIII/ar3.11.jpg'
import AraucariasIII12 from '../assets/imags/proyects/araucariasIII/ar3.12.jpg'
import AraucariasIII13 from '../assets/imags/proyects/araucariasIII/ar3.13.jpg'



import AraucariasVP from '../assets/imags/proyects/araucauriasV/portadaAR5.jpg'
import AraucariasV from '../assets/imags/proyects/araucauriasV/ar5.1.jpg'
import AraucariasV2 from '../assets/imags/proyects/araucauriasV/ar5.2.jpg'
import AraucariasV4 from '../assets/imags/proyects/araucauriasV/ar5.3.jpg'
import AraucariasV5 from '../assets/imags/proyects/araucauriasV/ar5.4.jpg'
import AraucariasV6 from '../assets/imags/proyects/araucauriasV/ar5.5.jpg'
import AraucariasV7 from '../assets/imags/proyects/araucauriasV/ar5.6.jpg'
import AraucariasV8 from '../assets/imags/proyects/araucauriasV/ar5.7.jpg'
import AraucariasV9 from '../assets/imags/proyects/araucauriasV/ar5.8.jpg'
import AraucariasV10 from '../assets/imags/proyects/araucauriasV/ar5.9.jpg'
import AraucariasV11 from '../assets/imags/proyects/araucauriasV/ar5.10.jpg'


import Araucarias4 from '../assets/imags/proyects/araucariasIV/araucarias4.1.jpg'
import AraucariasIV2 from '../assets/imags/proyects/araucariasIV/araucarias4.2.jpg'
import Araucarias4P from '../assets/imags/proyects/araucariasIV/portadaAr4.jpg'




import BahiaP from '../assets/imags/proyects/bahia/portadabahia.jpg'
import Bahia from '../assets/imags/proyects/bahia/bahia.jpg'
import Bahia2 from '../assets/imags/proyects/bahia/bahia.2.jpg'
import Bahia3 from '../assets/imags/proyects/bahia/bahia.3.jpg'
import Bahia4 from '../assets/imags/proyects/bahia/bahia.4.jpg'


import BelgranoP from '../assets/imags/proyects/belgrano/portadaBELGRANO.jpg'
import Belgrano from '../assets/imags/proyects/belgrano/belgrano1.jpg'
import Belgrano2 from '../assets/imags/proyects/belgrano/belgrano2 (2).jpg'
import Belgrano3 from '../assets/imags/proyects/belgrano/belgrano3 (2).jpg'


import CantonP from '../assets/imags/proyects/canton/portadaCANTON.jpg'
import Canton from '../assets/imags/proyects/canton/canton1.jpg'
import Canton2 from '../assets/imags/proyects/canton/canton1.2.jpg'
import Canton3 from '../assets/imags/proyects/canton/canton1.3.jpg'




import Costas from '../assets/imags/proyects/costas/costas (2).jpg'
import Costasport from '../assets/imags/proyects/costas/portcostas.jpg'
import Costas2 from '../assets/imags/proyects/costas/costas1.2.jpg'
import Costas3 from '../assets/imags/proyects/costas/costas1.3.jpg'


import SaucesP from '../assets/imags/proyects/saucesI/portadasau.jpg'
import Sauces from '../assets/imags/proyects/saucesI/sauces1.jpg'
import SaucesI2 from '../assets/imags/proyects/saucesI/sauces2.jpg'
import SaucesI3 from '../assets/imags/proyects/saucesI/sauces3.jpg'
import SaucesI4 from '../assets/imags/proyects/saucesI/sauces4.jpg'
import SaucesI5 from '../assets/imags/proyects/saucesI/sauces5.jpg'
import SaucesI6 from '../assets/imags/proyects/saucesI/sauces6.jpg'
import SaucesI7 from '../assets/imags/proyects/saucesI/sauces7.jpg'
import SaucesI8 from '../assets/imags/proyects/saucesI/sauces8.jpg'
import SaucesI9 from '../assets/imags/proyects/saucesI/sauces9.jpg'
import SaucesI10 from '../assets/imags/proyects/saucesI/sauces10.jpg'




import NunesP from '../assets/imags/proyects/nunes/portadaNUNEZ.jpg'
import Nunes from '../assets/imags/proyects/nunes/nune1.jpg'
import Nunes2 from '../assets/imags/proyects/nunes/nune2.jpg'
import Nunes3 from '../assets/imags/proyects/nunes/nune3.jpg'
import Nunes4 from '../assets/imags/proyects/nunes/nune4.jpg'
import Nunes5 from '../assets/imags/proyects/nunes/nune5.jpg'


import Riberas3 from '../assets/imags/proyects/riberas/riberas1.jpg'
import Riberas2 from '../assets/imags/proyects/riberas/riberas1.2.jpg'
import Riberas1 from '../assets/imags/proyects/riberas/riberas.13.jpg'
import Riberas from '../assets/imags/proyects/riberas/portadarib.jpg'

import SaucesVP from '../assets/imags/proyects/saucesV/portadaSAUCES5.jpg'
import SaucesV from '../assets/imags/proyects/saucesV/sauces5.1.jpg'
import SaucesV2 from '../assets/imags/proyects/saucesV/sauces5.2.jpg'



import YahtP from '../assets/imags/proyects/yaht/portadaYACHT.jpg'
import Yaht from '../assets/imags/proyects/yaht/Yaht1.jpg'
import Yaht2 from '../assets/imags/proyects/yaht/yaht2 (2).jpg'
import Yaht3 from '../assets/imags/proyects/yaht/AJUSTADA 3.jpg'

import TipasP from '../assets/imags/proyects/tipasI/portada TIP 23.jpg'
import Tipas2 from '../assets/imags/proyects/tipasI/tipas1.jpg'
import Tipas from '../assets/imags/proyects/tipasI/tipas1.2.jpg'

import VistasI8 from '../assets/imags/proyects/vistas/08-ajustada.jpg'
import VistasI7 from '../assets/imags/proyects/vistas/07-ajustada.jpg'
import VistasI6 from '../assets/imags/proyects/vistas/06-ajustada.jpg'
import VistasI5 from '../assets/imags/proyects/vistas/05-ajustada.jpg'
import VistasI4 from '../assets/imags/proyects/vistas/04-ajustada.jpg'
import VistasI2 from '../assets/imags/proyects/vistas/02-ajustada.jpg'
import VistasI3 from '../assets/imags/proyects/vistas/03-ajustada.jpg'
import VistasI from '../assets/imags/proyects/vistas/01-ajustada.jpg'
import VistasIP from '../assets/imags/proyects/vistas/portada VIST 227.jpg'


import VistasIIP from '../assets/imags/proyects/vistasII/vistas2portada.jpg'
import VistasII1 from '../assets/imags/proyects/vistasII/vistas2.1.jpg'
import VistasII2 from '../assets/imags/proyects/vistasII/vistas2.2.jpg'
import VistasII3 from '../assets/imags/proyects/vistasII/vistas2.3.jpg'


import RiberasII from '../assets/imags/proyects/riberasII/rib2.1.jpg'
import RiberasIIP from '../assets/imags/proyects/riberasII/portadarib2.jpg'
import RiberasII2 from '../assets/imags/proyects/riberasII/rib2.2.jpg'
import RiberasII3 from '../assets/imags/proyects/riberasII/rib2.3.jpg'
import RiberasII4 from '../assets/imags/proyects/riberasII/rib2.4.jpg'
import RiberasII5 from '../assets/imags/proyects/riberasII/rib2.5.jpg'
import RiberasII6 from '../assets/imags/proyects/riberasII/rib2.6.jpg'
import RiberasII7 from '../assets/imags/proyects/riberasII/rib2.7.jpg'
import RiberasII8 from '../assets/imags/proyects/riberasII/rib2.8.jpg'
import RiberasII9 from '../assets/imags/proyects/riberasII/rib2.9.jpg'
import RiberasII10 from '../assets/imags/proyects/riberasII/rib2.10.jpg'
import RiberasII11 from '../assets/imags/proyects/riberasII/rib2.12.jpg'
import RiberasII12 from '../assets/imags/proyects/riberasII/rib2.13.jpg'
import RiberasII13 from '../assets/imags/proyects/riberasII/rib211.jpg'

import LagosP from '../assets/imags/proyects/lagos/portadalagos.jpg'
import Lagos from '../assets/imags/proyects/lagos/lagos.jpg'
import Lagos2 from '../assets/imags/proyects/lagos/lagos2.jpg'
import Lagos3 from '../assets/imags/proyects/lagos/lagos3.jpg'
import Lagos4 from '../assets/imags/proyects/lagos/lagos4.jpg'
import Lagos5 from '../assets/imags/proyects/lagos/lagos5.jpg'



import SaucesIIIP from '../assets/imags/proyects/saucesIII/portada SAU 27 (1).jpg'
import SaucesIII from '../assets/imags/proyects/saucesIII/sauces3.jpg'
import SaucesIII2 from '../assets/imags/proyects/saucesIII/sauces3.2.jpg'
import SaucesIII3 from '../assets/imags/proyects/saucesIII/sauces3.3.jpg'


import BMarinas from '../assets/imags/proyects/bmarinas/bmarinas.jpg'
import BMarinas2 from '../assets/imags/proyects/bmarinas/bmarinas1.jpg'
import BMarinas3 from '../assets/imags/proyects/bmarinas/bmarinas3.jpg'

import TipasIIIP from '../assets/imags/proyects/tipasIII/TipasIIIP.jpg'
import TipasIII1 from '../assets/imags/proyects/tipasIII/TipasIII.jpg'


import CasaFoaP from '../assets/imags/proyects/casafoa/portada FOA.jpg'
import CasaFoa1 from '../assets/imags/proyects/casafoa/1 FOA.jpg'
import CasaFoa2 from '../assets/imags/proyects/casafoa/2 FOA.jpg'
import CasaFoa3 from '../assets/imags/proyects/casafoa/3 FOA.jpg'
import CasaFoa4 from '../assets/imags/proyects/casafoa/4 FOA.jpg'

import SaucesIVP from '../assets/imags/proyects/saucesIV/portadaSAU4.jpg'
import SaucesIV from '../assets/imags/proyects/saucesIV/sauces4.1.jpg'
import SaucesIV2 from '../assets/imags/proyects/saucesIV/sauces4.2.jpg'
import SaucesIV3 from '../assets/imags/proyects/saucesIV/sauces4.3.jpg'
import SaucesIV4 from '../assets/imags/proyects/saucesIV/sauces4.4.jpg'
import SaucesIV5 from '../assets/imags/proyects/saucesIV/sauces4.5.jpg'
import SaucesIV6 from '../assets/imags/proyects/saucesIV/sauces4.6.jpg'
import SaucesIV7 from '../assets/imags/proyects/saucesIV/sauces4.7.jpg'
import SaucesIV8 from '../assets/imags/proyects/saucesIV/sauces4.8.jpg'

import CantonIIport from '../assets/imags/proyects/cantonII/portcanton2.jpg'
import CantonII from '../assets/imags/proyects/cantonII/canton2.1.jpg'
import CantonII2 from '../assets/imags/proyects/cantonII/canton2.2.jpg'
import CantonII3 from '../assets/imags/proyects/cantonII/canton2.3.jpg'
import CantonII4 from '../assets/imags/proyects/cantonII/canton2.4.jpg'
import CantonII5 from '../assets/imags/proyects/cantonII/canton2.5.jpg'
import CantonII6 from '../assets/imags/proyects/cantonII/canton2.6.jpg'
import CantonII7 from '../assets/imags/proyects/cantonII/canton2.7.jpg'

import TipasIIP from '../assets/imags/proyects/tipasII/portada TIP 83.jpg'
import TipasII1 from '../assets/imags/proyects/tipasII/1 (5).jpg'
import TipasII2 from '../assets/imags/proyects/tipasII/2 (9).jpg'

import Villamarina from  '../assets/imags/proyects/villamarina/portadVM.jpg'
import Villamarina1 from  '../assets/imags/proyects/villamarina/vm1.jpg'
import Villamarina2 from  '../assets/imags/proyects/villamarina/vm2.jpg'
import Villamarina3 from  '../assets/imags/proyects/villamarina/vm3.jpg'
import Villamarina4 from  '../assets/imags/proyects/villamarina/vm4.jpg'
import Villamarina5 from  '../assets/imags/proyects/villamarina/vm5.jpg'
import Villamarina6 from  '../assets/imags/proyects/villamarina/vm6.jpg'
import Villamarina7 from  '../assets/imags/proyects/villamarina/vm7.jpg'
import Villamarina8 from  '../assets/imags/proyects/villamarina/vm8.jpg'
import Villamarina9 from  '../assets/imags/proyects/villamarina/vm9.jpg'
import Villamarina10 from  '../assets/imags/proyects/villamarina/vm10.jpg'
import Villamarina11 from  '../assets/imags/proyects/villamarina/vm11.jpg'
import Villamarina12 from  '../assets/imags/proyects/villamarina/vm12.jpg'
import Villamarina13 from  '../assets/imags/proyects/villamarina/vm13.jpg'




import VillamarinaIIP from  '../assets/imags/proyects/villamarinaII/portadaVM2.jpg'
import VillamarinaII from  '../assets/imags/proyects/villamarinaII//vm2.1.jpg'
import VillamarinaII2 from  '../assets/imags/proyects/villamarinaII/vm2.2.jpg'
import VillamarinaII3 from  '../assets/imags/proyects/villamarinaII/vm2.3.jpg'
import VillamarinaII4 from  '../assets/imags/proyects/villamarinaII/vm2.4.jpg'
import VillamarinaII5 from  '../assets/imags/proyects/villamarinaII/vm2.5.jpg'
import VillamarinaII6 from  '../assets/imags/proyects/villamarinaII/vm2.6.jpg'
import VillamarinaII7 from  '../assets/imags/proyects/villamarinaII/vm2.7.jpg'
import VillamarinaII8 from  '../assets/imags/proyects/villamarinaII/vm2.8.jpg'
import VillamarinaII9 from  '../assets/imags/proyects/villamarinaII/vm2.9.jpg'
import VillamarinaII10 from  '../assets/imags/proyects/villamarinaII/vm2.10.jpg'
import VillamarinaII11 from  '../assets/imags/proyects/villamarinaII/vm2.11.jpg'

import GlorietasP from  '../assets/imags/proyects/glorietas/portglorietas.jpg'
import Glorietas from  '../assets/imags/proyects/glorietas/portglorietas.jpg'
import Glorietas1 from  '../assets/imags/proyects/glorietas//gloritetas1.jpg'
import Glorietas2 from  '../assets/imags/proyects/glorietas//glorietas2.jpg'
import Glorietas3 from  '../assets/imags/proyects/glorietas//glorietas3.jpg'

export const data = [
  {
    id: 1,
    name: "YACHT",
    portada:YahtP,
    image:[Yaht,Yaht2,Yaht3],
    ubicación:"NORDELTA - TIGRE, BS. AS",
  },
  {
    id: 2,
    name: "TIPAS",
    portada: TipasP,
    image: [Tipas,Tipas2],
    ubicación:"NORDELTA - TIGRE, BS. AS",
  },
  {
    id: 3,
    name: "RIBERAS",
    portada: Riberas,
    image: [Riberas1,Riberas2,Riberas3],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 4,
    name: "VISTAS I",
    portada: VistasIP,
    image: [VistasI,VistasI2, VistasI3,VistasI4,VistasI5,VistasI6,VistasI7,VistasI8],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id:5,
    name: "VISTAS II",
    portada: VistasIIP,
    image: [VistasII1,VistasII2,VistasII3,],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id:6,
    name: "ARAUCARIAS",
    portada: AraucariasP,
    image: [Araucarias,AraucariasI2,AraucariasI3,AraucariasI4,AraucariasI5,AraucariasI6,AraucariasI7,AraucariasI8,AraucariasI9,AraucariasI10,AraucariasI11],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },

  {
    id: 7,
    name: "ARAUCARIAS II",
    portada: Araucarias2P,
    image: [AraucariasII1,AraucariasII2,AraucariasII3,AraucariasII4,AraucariasII5,AraucariasII6,AraucariasII7,AraucariasII8,AraucariasII9,AraucariasII10,AraucariasII11,AraucariasII12,],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 8,
    name: "ARAUCARIAS III",
    portada: Araucarias3P,
    image: [Araucarias3,AraucariasIII2,AraucariasIII3,AraucariasIII4,AraucariasIII5,AraucariasIII6,AraucariasIII7,AraucariasIII8,AraucariasIII9,AraucariasIII10,AraucariasIII11,AraucariasIII12,AraucariasIII13],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id:9,
    name: "ARAUCARIAS IV",
    portada:  Araucarias4P,
    image: [Araucarias4,AraucariasIV2],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 10,
    name: "VILA MARINA",
    portada: Villamarina,
    image: [Villamarina1, Villamarina2,Villamarina3,Villamarina4,Villamarina5,Villamarina6,Villamarina7,Villamarina8,Villamarina9,Villamarina10,Villamarina11,Villamarina12,Villamarina13,],
    ubicación:"VILLANUEVA - TIGRE, BS. AS.",
  },
  {
    id: 11,
    name: "CASA FOA 2023",
    portada: CasaFoaP,
    image: [CasaFoa1, CasaFoa2,CasaFoa3,CasaFoa4],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 12,
    name: "LAGOS",
    portada: LagosP,
    image: [Lagos,Lagos2, Lagos3,Lagos4,Lagos5],
    ubicación:"NORDELTA - TIGRE, BS. AS",
  },
  {
    id: 13,
    name: "BAHIA",
    portada: BahiaP,
    image: [Bahia,Bahia2,Bahia3,Bahia4],
    ubicación:"NORDELTA - TIGRE, BS. AS",
  },
  {
    id: 14,
    name: "ACACIAS",
    portada:Acaciaspor,
    image: [Acacias, Acacias2,Acacias3,Acacias4,],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 15,
    name: "RIBERAS II",
    portada: RiberasIIP,
    image: [RiberasII,RiberasII2,RiberasII3,RiberasII4,RiberasII5,RiberasII6,RiberasII7,RiberasII8,RiberasII9,RiberasII10,RiberasII11,RiberasII12],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 16,
    name: "ARAUCARIAS V",
  portada: AraucariasVP,
    image: [AraucariasV,AraucariasV2,AraucariasV4,AraucariasV5,AraucariasV6,AraucariasV7,AraucariasV8,AraucariasV9,AraucariasV10,AraucariasV11],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 17,
    name: "TIPAS II",
  portada: TipasIIP,
    image: [TipasII1,TipasII2,],
    ubicación:"NORDELTA - TIGRE , BS. AS.",
  },
  {
    id: 18,
    name: "SAUCES",
    portada: SaucesP,
    image: [Sauces,SaucesI2,SaucesI3,SaucesI4,SaucesI5,SaucesI6,SaucesI7,SaucesI8,SaucesI9,SaucesI10],
    ubicación:"NORDELTA - TIGRE, BS. AS",
  },
  {
    id: 19,
    name: "SAUCES III",
    portada: SaucesIIIP,
    image: [SaucesIII, SaucesIII2,SaucesIII3,],
    ubicación:"NORDELTA - TIGRE, BS. AS",
  },
  {
    id: 20,
    name: "SAUCES IV",
    portada: SaucesIVP,
    image: [SaucesIV, SaucesIV2,SaucesIV3,SaucesIV4,SaucesIV5,SaucesIV6,SaucesIV7,SaucesIV8],
    ubicación:"NORDELTA - TIGRE, BS. AS",
  },
  {
    id: 21,
    name: "SAUCES V",
    portada: SaucesVP,
    image: [SaucesV, SaucesV2],
    ubicación:"NORDELTA - TIGRE, BS. AS.",
  },
  {
    id: 22,
    name: "CANTON I",
    portada: CantonP,
    image: [Canton,Canton2,Canton3,],
    ubicación:"EL GOLF , EL CANTON - ESCOBAR, BS. AS",
  },
  {
    id: 23,
    name: "VILA MARINA II",
    portada: VillamarinaIIP,
    image: [VillamarinaII,VillamarinaII2, VillamarinaII3, VillamarinaII4,VillamarinaII5,VillamarinaII6,VillamarinaII7,VillamarinaII8,VillamarinaII9,VillamarinaII10,VillamarinaII11],
    ubicación:"VILLANUEVA - TIGRE, BS. AS.",
  },
  {
    id:24,
    name: "CANTON II",
    portada: CantonIIport,
    image: [CantonII, CantonII2,CantonII3,CantonII4,CantonII5,CantonII6,CantonII7],
    ubicación:"EL GOLF , EL CANTON - ESCOBAR, BS. AS",
  },
  {
    id: 25,
    name: "COSTAS",
    portada: Costasport,
    image: [Costas,Costas2,Costas3,],
    ubicación:"PUERTOS - ESCOBAR, BS. AS.",
  },
  {
    id: 26,
    name: "NUNEZ",
    portada: NunesP,
    image:[Nunes,Nunes2,Nunes3, Nunes4, Nunes5],
    ubicación:"NUÑEZ – CABA , BS. AS.",
  },
  {
    id: 27,
    name: "ALISOS",
    portada: AlissosP,
    image: [Alissos,Alisos2,Alisos3,Alisos4,Alisos5],
    ubicación:"NORDELTA - TIGRE, BS. Anpm startS",
  },
  {
    id: 28,
    name: "MARINAS",
    portada: BMarinas,
    image: [BMarinas2,BMarinas3],
    ubicación:"VILA MARINA, VILLANUEVA - TIGRE, BS. AS.",
  },
  {
    id: 29,
    name: "TIPAS III",
    portada:TipasIIIP,
    image: [TipasIII1],
    ubicación:"NORDELTA - TIGRE, BS. AS.",
  },
  {
    id: 30,
    name: "GLORIETAS",
    portada: GlorietasP,
    image: [Glorietas1, Glorietas2, Glorietas3],
    ubicación:"NORDELTA - TIGRE, BS. AS.",
  },
  {
    id: 31,
    name: "BELGRANO",
    portada: BelgranoP,
    image: [Belgrano,Belgrano2,Belgrano3,],
      ubicación:"BELGRANO – CABA , BS. AS.",
  },
];