export default [
    {
        name: "Home",
        route: "/"
    },
    {
        name: "Estudio",
        route: "/estudio"
    },
    {
        name: "Proyectos",
        route: "/proyectos"
    },
    {
        name: "Contacto",
        route: "/contact"
    },
]